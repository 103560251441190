import moment from 'moment';
import enums from 'enums/index';
import { dangerColor, primaryColor } from 'assets/jss/material-dashboard-pro-react';
const { TerminationReasonCategories, HelperTerminationReasons, ClientTerminationReasons } = enums;

const invoiceTypesFilterObj = {
  [enums.InvoiceTypes.EB]: 'EB',
  [enums.InvoiceTypes.VP]: 'VP',
  [enums.InvoiceTypes.B_EB]: 'B_EB',
  [enums.InvoiceTypes.B_VP]: 'B_VP',
  [enums.InvoiceTypes.PRIVATE_EXTRA]: 'PRIVATE_EXTRA',
  [enums.InvoiceTypes.PRIVATE_ALL]: 'PRIVATE_ALL',
};
const clientMonthlyStatusFilter = {
  [enums.ClientInvoicesStatuses.UNCONFIRMED]: 'Unconfirmed',
  [enums.ClientInvoicesStatuses.CONFIRMED]: 'Confirmed',
  [enums.ClientInvoicesStatuses.DECLINED]: 'Declined',
};
const PrivateInvoicesStatusesObj = {
  [enums.PrivateInvoicesStatuses.CREATED]: 'CREATED',
  [enums.PrivateInvoicesStatuses.SENT]: 'SENT',
  [enums.PrivateInvoicesStatuses.PAID]: 'PAID',
};

export default {
  gendersObj: {
    [enums.Gender.MALE]: 'Herr',
    [enums.Gender.FEMALE]: 'Frau',
  },
  helperGendersObj: {
    [enums.Gender.MALE]: 'Helfer',
    [enums.Gender.FEMALE]: 'Helferin',
  },
  clientGendersObj: {
    [enums.Gender.MALE]: 'Kunde',
    [enums.Gender.FEMALE]: 'Kundin',
  },
  familyStatusesObj: {
    [enums.FamilyStatus.SINGLE]: 'Single',
    [enums.FamilyStatus.MARRIED]: 'Married',
  },
  perOptionsObj: {
    [enums.Per.WEEK]: 'Week',
    [enums.Per.TWO_WEEKS]: '14 days',
    [enums.Per.MONTH]: 'Month',
    [enums.Per.QUARTER]: 'Quarter',
    [enums.Per.HALF_YEAR]: 'Half Year',
    [enums.Per.YEAR]: 'Year',
  },
  salutationsObj: {
    [enums.Salutation.DR]: 'Dr.',
    [enums.Salutation.DR_MED]: 'Dr. med.',
    [enums.Salutation.PROF]: 'Prof.',
    [enums.Salutation.PROF_DR]: 'Prof. Dr.',
    [enums.Salutation.PROF_DR_MED]: 'Prof. Dr. med.',
  },
  RecommendedByObj: {
    [enums.RecommendedBy.NURSING_BASE]: 'Pflegestützpunkt',
    [enums.RecommendedBy.NURSING_SERVICE]: 'Pflegedienst',
    [enums.RecommendedBy.NURSING_NAVIGATOR]: 'Pflegenavigator',
    [enums.RecommendedBy.INTERNET]: 'Internet',
    [enums.RecommendedBy.FRIEND]: 'Bekannte/r',
    [enums.RecommendedBy.MDK]: 'MDK',
    [enums.RecommendedBy.NEWSPAPER]: 'Zeitung',
    [enums.RecommendedBy.FLYER]: 'Flyer',
    [enums.RecommendedBy.HEALTH_INSURANCE_LIST]: 'Liste Krankenkasse',
    [enums.RecommendedBy.CLIENT]: 'Kunde',
    [enums.RecommendedBy.HELPER]: 'Helfer/in',
    [enums.RecommendedBy.INSTAGRAM]: 'Instagram',
    [enums.RecommendedBy.EBAY]: 'Ebay',
    [enums.RecommendedBy.INDEED]: 'Indeed',
    [enums.RecommendedBy.STUDENT_JOB]: 'Schülerjob',
    [enums.RecommendedBy.GOOGLE]: 'Google',
    [enums.RecommendedBy.OTHER]: 'Sonstige',
  },
  helperSituationsObj: {
    [enums.HelperSituation.STUDENT_1]: 'Schüler/in',
    [enums.HelperSituation.STUDENT_2]: 'Student/in',
    [enums.HelperSituation.TRAINEE]: 'Auszubildende/r',
    [enums.HelperSituation.APPLICANT]: 'Studienbewerber/in',
    [enums.HelperSituation.STUDENT_NEXT_SEMESTER]: 'Student/in ab kommenden Semester',
    [enums.HelperSituation.LOOKING_FOR_TRAINING]: 'Ausbildung suchend',
    [enums.HelperSituation.WORKER]: 'Arbeiter/in',
    [enums.HelperSituation.SOCIAL_SERVICE]: 'FSJ',
    [enums.HelperSituation.LOOKING_FOR_JOB]: 'Arbeitssuchend',
    [enums.HelperSituation.OTHER]: 'Sonstige',
  },
  schoolDegreesObj: {
    [enums.SchoolDegree.NO_DEGREE]: 'Ohne Schulabschluss',
    [enums.SchoolDegree.ELEMENTARY]: 'Haupt-/Volksschulabschluss',
    [enums.SchoolDegree.MIDDLE_SCHOOL]: 'mittlere Reife oder gleichw. Abschluss',
    [enums.SchoolDegree.ABITUR]: 'Abitur/Fachabitur',
    [enums.SchoolDegree.UNKNOWN]: 'Abschluss unbekannt',
  },
  workDegreesObj: {
    [enums.WorkDegree.NO_DEGREE]: 'Ohne Berufsausbildung',
    [enums.WorkDegree.RECOGNIZED_PROFESSIONAL_TRAINING]: 'Abschluss einer anerkannten Berufsausbildung',
    [enums.WorkDegree.TECHNICAL_DEGREE]: 'Meister-/Techniker- oder gleichw. Fachschulabschluss',
    [enums.WorkDegree.BACHELOR]: 'Bachelor',
    [enums.WorkDegree.DIPLOMA]: 'Diplom/Magister/Master/Staatsexamen',
    [enums.WorkDegree.PROMOTION]: 'Promotion',
    [enums.WorkDegree.UNKNOWN]: 'Ausbildung unbekannt',
  },
  insuranceTypesObj: {
    [enums.InsuranceType.LEGAL]: 'Gesetzlich',
    [enums.InsuranceType.PRIVATE]: 'Privat',
  },
  healthInsuranceObj: {
    [enums.HealthInsurance.NO_CONTRIBUTION]: 'Kein Beitrag',
    [enums.HealthInsurance.GENERAL_CONTRIBUTION]: 'allgemeiner Beitrag',
    [enums.HealthInsurance.REDUCED_CONTRIBUTION]: 'ermäßigter Beitrag',
    [enums.HealthInsurance.FLAT_RATE]: 'pauschal',
    [enums.HealthInsurance.REFUND_OF_PRIVATE_INSURANCE]: 'Erstattung private Krankenversicherung',
    [enums.HealthInsurance.VOLUNTARILY_INSURED]: 'freiwillig gesetzlich Versicherte',
  },
  pensionInsuranceObj: {
    [enums.PensionInsurance.NO_AMOUNT]: 'Kein Betrag',
    [enums.PensionInsurance.FULL_AMOUNT]: 'voller Betrag',
    [enums.PensionInsurance.HALF_AMOUNT]: 'halber Betrag',
    [enums.PensionInsurance.FLAT_RATE]: 'Pauschalbeitrag gering',
    [enums.PensionInsurance.EMPLOYED]: 'Beschäftigt',
    [enums.PensionInsurance.PENSION_INSURANCE_REFUND]: 'Erstattung Rentenversicherung',
  },
  drivingMethodsObj: {
    [enums.DrivingMethod.FOOT]: 'Zu Fuß',
    [enums.DrivingMethod.LOCAL_PUBLIC_TRANSPORT]: 'Öffentlicher Nahverkehr',
    [enums.DrivingMethod.BICYCLE]: 'mit dem Fahrrad',
    [enums.DrivingMethod.CAR]: 'mit dem Auto',
    [enums.DrivingMethod.MOTORCYCLE]: 'mit dem Motorrad',
    [enums.DrivingMethod.SCOOTER]: 'mit dem Roller',
  },
  invitationsStatusObj: {
    ['-1']: 'Not invited',
    [enums.InvitationStatus.PENDING]: 'Pending',
    [enums.InvitationStatus.REJECTED]: 'Rejected',
    [enums.InvitationStatus.ACCEPTED]: 'Accepted',
    [enums.InvitationStatus.EXPIRED]: 'Expired',
    [enums.InvitationStatus.HOLD]: 'Hold',
    [enums.InvitationStatus.MATCHED]: 'Matched',
    [enums.InvitationStatus.FAILED]: 'Failed',
    [enums.InvitationStatus.CANCELED]: 'Canceled',
    [enums.InvitationStatus.COMPLETED]: 'Completed',
    [enums.InvitationStatus.SCHEDULED]: 'Scheduled',
  },
  visitsStatusObj: {
    [enums.VisitStatus.PLANNED]: 'Planned',
    [enums.VisitStatus.CONFIRMED]: 'Confirmed',
    [enums.VisitStatus.DECLINED_BY_ADMIN]: 'Declined by admin',
  },
  monthlyVisitsStatusObj: {
    [enums.MonthlyVisitStatuses.UNCONFIRMED]: 'UnConfirmed',
    [enums.MonthlyVisitStatuses.CONFIRMED]: 'Confirmed',
    [enums.MonthlyVisitStatuses.DECLINED]: 'Declined',
  },
  clientMonthlyStatusFilter: {
    ...clientMonthlyStatusFilter,
  },
  clientMonthlyStatus: {
    [enums.ClientInvoicesStatuses.ALL]: 'All',
    ...clientMonthlyStatusFilter,
    [enums.ClientInvoicesStatuses.READY_FOR_INVOICE]: 'Ready For Invoices',
  },

  workLocationsObj: {
    [enums.WorkLocations.BERLIN]: 'Berlin',
    [enums.WorkLocations.BOCHUM]: 'Bochum',
    [enums.WorkLocations.CHEMNITZ]: 'Chemnitz',
    [enums.WorkLocations.DORTMUND]: 'Dortmund',
    [enums.WorkLocations.DRESDEN]: 'Dresden',
    [enums.WorkLocations.DUISBURG]: 'Duisburg',
    [enums.WorkLocations.DÜSSELDORF]: 'Düsseldorf',
    [enums.WorkLocations.ESSEN]: 'Essen',
    [enums.WorkLocations.FRANKFURT]: 'Frankfurt',
    [enums.WorkLocations.KÖLN]: 'Köln',
    [enums.WorkLocations.LEIPZIG]: 'Leipzig',
    [enums.WorkLocations.WIESBADEN]: 'Wiesbaden',
    [enums.WorkLocations.KIEL]: 'Kiel',
    [enums.WorkLocations.NEUMÜNSTER]: 'Neumünster',
    [enums.WorkLocations.FREIBURG]: 'Freiburg',
    [enums.WorkLocations.AUGSBURG]: 'Augsburg',
    [enums.WorkLocations.NÜRNBERG]: 'Nürnberg',
  },
  helperTerminationStatusesObj: {
    [enums.HelperTerminationStatuses.REQUESTED]: 'Requested',
    [enums.HelperTerminationStatuses.ACCEPTED]: 'Accepted',
    [enums.HelperTerminationStatuses.DECLINED]: 'Decline',
    [enums.HelperTerminationStatuses.DONE]: 'Done',
  },
  helperVacationStatusesObj: {
    [enums.HelperVacationStatuses.REQUESTED]: 'Requested',
    [enums.HelperVacationStatuses.ACCEPTED]: 'Accepted',
    [enums.HelperVacationStatuses.DECLINED]: 'Declined',
  },
  noReplacementNeededReasonObj: {
    [enums.NoReplacementNeededReason.CLIENT_NO_NEED]: 'Client does not need replacement',
    [enums.NoReplacementNeededReason.CLIENT_ON_VACATION]: 'Client is on Vacation',
    [enums.NoReplacementNeededReason.HOURS_COMPLETED]: 'Already Completed all Hours',
    [enums.NoReplacementNeededReason.OTHER]: 'Other',
  },

  invitationDeclineReasonObj: {
    [enums.InvitationDeclineReason.DISTANCE_TIME_TOO_LONG]: 'Distance/journey time too long',
    [enums.InvitationDeclineReason.ORDER_UNCLEAR]: 'Order text unclear/insufficient',
    [enums.InvitationDeclineReason.EXCESSIVE_AMOUNT]: 'Excessive amount (hours)',
    [enums.InvitationDeclineReason.TOO_LITTLE_SCOPE]: 'Too little scope (hours)',
    [enums.InvitationDeclineReason.TYPE_OF_SERVICE]: 'Type of service',
    [enums.InvitationDeclineReason.ZERO_HELPER_CAPACITY]: 'Zero Helper Capacity',
    [enums.InvitationDeclineReason.OTHER]: 'Other',
  },
  trueFalseObj: {
    true: 'True',
    false: 'False',
  },
  jiraPrioritiesObj: {
    Highest: 'Highest',
    High: 'High',
    Medium: 'Medium',
    Low: 'Low',
    Lowest: 'Lowest',
  },
  helperDocumentTypesOBJ: {
    'Ausweisdokument (Personalausweis/Reisepass)': enums.HelperDocumentTypes.ID,
    Vertrag: enums.HelperDocumentTypes.CONTRACT,
    Arbeitsbelehrung: enums.HelperDocumentTypes.WORK_INSTRUCTION,
    Aufnahmebogen: enums.HelperDocumentTypes.RECORDING_SHEET,
    '3G Nachweis': enums.HelperDocumentTypes.PROOF_3G,
    Schulungszertifikat: enums.HelperDocumentTypes.TRAINING_CERTIFICATE,
    'Schul-/Immatrikulations-/Ausbildungsbescheinigung': enums.HelperDocumentTypes.HIGHER_EDUCATION_CERTIFICATE,
    Führungszeugnis: enums.HelperDocumentTypes.CRIMINAL_RECORD,
    Kündigung: enums.HelperDocumentTypes.TERMINATION,
    'Aufenthaltstitel/Arbeitserlaubnis': enums.HelperDocumentTypes.RESIDENCE_PERMIT,
    'AU-Bescheinigung': enums.HelperDocumentTypes.AU_CERTIFICATE,
    'Vertrag anderer Job': enums.HelperDocumentTypes.OTHER_JOB_CONTRACT,
    Einkommensbescheinigung: enums.HelperDocumentTypes.INCOME_CERTIFICATE,
    'Brief vom Minijob': enums.HelperDocumentTypes.LETTER_FROM_MINI_JOB,
    'Brief vom Jobcenter': enums.HelperDocumentTypes.LETTER_FROM_THE_JOB_CENTER,
    Fahrtenbuch: enums.HelperDocumentTypes.LOG_BOOK,
    Andere: enums.HelperDocumentTypes.OTHER,
  },
  clientDocumentTypesOBJ: {
    Vertrag: enums.ClientDocumentTypes.CONTRACT,
    'Abtretungserklärung(en)': enums.ClientDocumentTypes.DECLARATION_OF_ASSIGNMENT,
    Kündigung: enums.ClientDocumentTypes.TERMINATION,
    Verhinderungspflege: enums.ClientDocumentTypes.PREVENTION_CARE,
    Betreuerausweis: enums.ClientDocumentTypes.SUPERVISOR_ID,
    Andere: enums.ClientDocumentTypes.OTHER,
  },
  clientLetterTypesOBJ: {
    [enums.ClientLetterTypes.CONTRACT]: 'Vertrag',
    [enums.ClientLetterTypes.OTHER]: 'Andere',
  },
  invoiceLetterTypesObj: {
    [enums.InvoiceLetterTypes.INVOICE]: 'invoice',
    [enums.InvoiceLetterTypes.INVOICE_REMINDER]: 'Invoice Reminder',
  },
  letterTypesOBJ: {
    [enums.LetterTypes.CLIENT_CONTRACT]: 'Client Contract',
    [enums.LetterTypes.INVOICE]: 'Invoice',
    [enums.LetterTypes.INVOICE_REMINDER]: 'Invoice Reminder',
    [enums.LetterTypes.MANUAL]: 'Manual',
    [enums.LetterTypes.OTHER]: 'Other',
  },
  clientLetterStatusesOBJ: {
    [enums.ClientLetterStatuses.ACCEPTANCE_OF_THE_SHIPMENT]: 'acceptance of the shipment',
    [enums.ClientLetterStatuses.PROCESSING_OF_THE_SHIPMENT]: 'processing of the shipment',
    [enums.ClientLetterStatuses.DELIVERY_TO_THE_PRINTING_CENTER]: 'Delivery to the printing center',
    [enums.ClientLetterStatuses.PROCESSING_IN_PRINTING_CENTER]: 'Processing in printing center',
    [enums.ClientLetterStatuses.ERROR]: 'processing errors',
  },
  clientStatusObj: {
    [enums.ClientStatus.NOT_INTERESTED]: 'Not Interested',
    [enums.ClientStatus.DRAFT]: 'Draft',
    [enums.ClientStatus.ACTIVE]: 'Activ',
    [enums.ClientStatus.INACTIVE]: 'InActive',
    [enums.ClientStatus.TERMINATED]: 'Terminated',
    [enums.ClientStatus.FREEZED]: 'Freezed',
  },
  emailStatusObj: {
    [enums.EmailStatuses.PROCESSED]: 'processed',
    [enums.EmailStatuses.DROPPED]: 'dropped',
    [enums.EmailStatuses.DELIVERED]: 'delivered',
    [enums.EmailStatuses.DEFERRED]: 'deferred',
    [enums.EmailStatuses.BOUNCE]: 'bounce',
    [enums.EmailStatuses.OPEN]: 'open',
    [enums.EmailStatuses.CLICK]: 'click',
    [enums.EmailStatuses.SPAM_REPORT]: 'spam report',
    [enums.EmailStatuses.UNSUBSCRIBE]: 'unsubscribe',
    [enums.EmailStatuses.GROUP_UNSUBSCRIBE]: 'group unsubscribe',
    [enums.EmailStatuses.GROUP_RESUBSCRIBE]: 'group resubscribe',
  },
  emailTypesObj: {
    [enums.EmailTypes.NOTIFICATION]: 'notification',
    [enums.EmailTypes.VERIFICATION]: 'verification',
    [enums.EmailTypes.RESET_PASSWORD]: 'resetPassword',
    [enums.EmailTypes.HELPER_INTERVIEW_DECLINED]: 'helperInterviewDeclined',
    [enums.EmailTypes.HELPER_INTERVIEW_TOTALLY_DECLINED]: 'helperInterviewTotallyDeclined',
    [enums.EmailTypes.HELPER_TERMINATION_ACCEPTED]: 'helperTerminationAccepted',
    [enums.EmailTypes.INVOICE_COPY]: 'invoiceCopy',
    [enums.EmailTypes.INVOICE]: 'invoice',
    [enums.EmailTypes.REMINDER]: 'reminder',
    [enums.EmailTypes.VISIT_REPORT8]: 'visitReport',
    [enums.EmailTypes.HELPER_INTERVIEW_ACCEPTED]: 'helperInterviewAccepted',
    [enums.EmailTypes.UNDER_AGED_HELPER_PARENT_EMAIL]: 'underAgedHelperParentEmail',
    [enums.EmailTypes.UNDER_AGED_HELPER_PARENT_EMAIL_CONTRACT_SIGNED]: 'underAgedHelperParentEmailContractSigned',
  },
  helperStatusObj: {
    [enums.HelperStatus.ACTIVE]: 'Active',
    [enums.HelperStatus.INACTIVE]: 'Inactive',
    [enums.HelperStatus.TERMINATED]: 'Terminated',
    [enums.HelperStatus.TERMINATED_WITHOUT_VISIT]: 'Terminated without visit',
  },
  helperDraftStatusObj: {
    [enums.HelperStatus.INVITEE]: 'Invitee',
    [enums.HelperStatus.EMAIL_VERIFIED]: 'Email Verified',
    [enums.HelperStatus.NOT_INTERESTED]: 'Not Interested',
    [enums.HelperStatus.NOT_ACCEPTED]: 'Not Accepted',
    [enums.HelperStatus.ACCEPTED]: 'Accepted',
    [enums.HelperStatus.FAILED]: 'Failed',
  },
  interviewTypesObj: {
    [enums.InterviewTypes.YES]: 'Yes',
    [enums.InterviewTypes.NO_SHOW]: 'No Show',
    [enums.InterviewTypes.CANCELED]: 'Canceled',
  },
  jiraStatusesObj: {
    [enums.JiraStatuses.TODO]: 'To Do',
    [enums.JiraStatuses.IN_PROGRESS]: 'In Progress',
    [enums.JiraStatuses.REVIEW]: 'Review',
    [enums.JiraStatuses.TESTING]: 'Testing',
    [enums.JiraStatuses.DONE]: 'Done',
  },
  confirmedTypesObj: {
    [enums.InterviewStatus.NO_SHOW_CONFIRMED]: 'Yes',
    [enums.InterviewStatus.NO_SHOW_NOT_CONFIRMED]: 'No',
  },
  decisionOptionsObj: {
    [enums.InterviewStatus.VERIFICATION]: 'Verification',
    [enums.InterviewStatus.HELPER_DECLINED]: 'Declined',
    [enums.InterviewStatus.DISCUSS]: 'Discuss',
    [enums.InterviewStatus.TOTALLY_DECLINED]: 'Totally Declined',
  },
  educationLevelObj: {
    [enums.EducationLevel.TRAINING]: 'Ausbildung',
    [enums.EducationLevel.BACHELOR]: 'Bachelor',
    [enums.EducationLevel.SCHOOL]: 'Schule',
    [enums.EducationLevel.MASTER]: 'Master',
  },
  vaccinationTypesObj: {
    [enums.VaccinationTypes.RECOVERED]: 'Genesen',
    [enums.VaccinationTypes['0']]: '0',
    [enums.VaccinationTypes['1']]: '1',
    [enums.VaccinationTypes['2']]: '2',
    [enums.VaccinationTypes['3']]: '3',
    [enums.VaccinationTypes['4']]: '4',
  },
  clientTasksObj: {
    [enums.ClientsTasks.NEEDS_HELPER]: 'Bedürfnisse Helfer',
    [enums.ClientsTasks.HAS_NO_CONTRACT]: 'Hat keinen Vertrag',
    [enums.ClientsTasks.REPLACEMENT]: 'Ersatzeinladung',
    [enums.ClientsTasks.NOT_READY_FOR_INVITATION]: 'Nicht bereit für die Einladung',
    [enums.ClientsTasks.CHECK_UP_CALL]: 'Anruf überprüfen',
  },
  helperDocumentsTypesObj: {
    [enums.HelperDocumentTypes.CRIMINAL_RECORD]: 'Criminal Record',
    [enums.HelperDocumentTypes.HIGHER_EDUCATION_CERTIFICATE]: 'Higher Education Certificate',
  },
  InvoiceTypes: {
    EB_LAST_YEAR: 0,
    EB: 1,
    VP: 2,
    B_EB: 3,
    B_VP: 4,
    PRIVATE_EXTRA: 5,
    PRIVATE_ALL: 6,
  },
  invoiceTypesFilterObj: {
    ...invoiceTypesFilterObj,
  },
  publicInvoiceTypesFilterObj: {
    [enums.InvoiceTypes.EB]: 'EB',
    [enums.InvoiceTypes.VP]: 'VP',
  },
  privateInvoicesStatusesFilter: {
    ...PrivateInvoicesStatusesObj,
  },
  invoiceTypesObj: {
    [enums.InvoiceTypes.EB_LAST_YEAR]: 'EB_LAST_YEAR',
    [enums.InvoiceTypes.EB]: 'EB',
    [enums.InvoiceTypes.VP]: 'VP',
    [enums.InvoiceTypes.PRIVATE_ALL]: 'PRIVATE_ALL',
    [enums.InvoiceTypes.PRIVATE_EXTRA]: 'PRIVATE_EXTRA',
    [enums.InvoiceTypes.B_EB]: 'B_EB',
    [enums.InvoiceTypes.B_VP]: 'B_VP',
  },
  invitationsTypesObj: {
    [enums.InvitationsTypes.STANDARD]: 'Standard',
    [enums.InvitationsTypes.REPLACEMENT]: 'Replacement',
  },
  orderBy: {
    [enums.OrderBy.FIRST_NAME]: 'Vor Name',
    [enums.OrderBy.LAST_NAME]: 'Nach Name',
    [enums.OrderBy.CREATED_AT]: 'Hergestellt in',
  },
  statisticsHelpersOrderBy: {
    [enums.StatisticsHelpersOrderBy.FIRST_NAME]: 'First Name',
    [enums.StatisticsHelpersOrderBy.LAST_NAME]: 'Last Name',
    [enums.StatisticsHelpersOrderBy.CREATED_AT]: 'Created at',
    [enums.StatisticsHelpersOrderBy.START_DATE]: 'Start Date',
    [enums.StatisticsHelpersOrderBy.INTERVIEW_DATE]: 'Interview Date',
  },
  invoicesOrderBy: {
    [enums.InvoicesOrderBy.DOWNLOAD_COUNT]: 'Download-Zähler',
    [enums.InvoicesOrderBy.CREATED_AT]: 'Hergestellt in',
  },
  emailsOrderBy: {
    [enums.EmailsOrderBy.CREATED_AT]: 'Created At',
  },
  createInvitationReasons: {
    [enums.CreateInvitationReasons.HELPER_TERMINATED]: 'Helper Terminated',
    [enums.CreateInvitationReasons.HELPER_VACATION]: 'Helper in Vacation',
  },
  cancelInvitationReasons: {
    [enums.CancelInvitationReasons.WRONG_INVITATION]: 'Wrong Invitation',
    [enums.CancelInvitationReasons.CLIENT_TEMPORARILY_UNAVAILABLE]: 'Client temporarily unavailable',
    [enums.CancelInvitationReasons.OTHER]: 'Other',
  },
  interviewCancelReasons: {
    [enums.InterviewCancelReasons.ANOTHER_JOB]: 'andere Arbeit',
    [enums.InterviewCancelReasons.NOT_INTERESTED]: 'nicht interessiert',
    [enums.InterviewCancelReasons.NO_TIME]: 'keine zeit',
    [enums.InterviewCancelReasons.OTHER]: 'Sonstiges',
  },
  exclusionCriteriaObj: {
    [enums.ExclusionCriteria.NO_LONG_TERM]: 'keine langfristige arbeit bei juhi',
    [enums.ExclusionCriteria.TOO_YOUNG]: 'Zu jung',
    [enums.ExclusionCriteria.TOO_OLD]: 'Zu alt',
    [enums.ExclusionCriteria.NO_KNOWLEDGE_OF_GERMAN]: 'keine deutschkenntnisse',
    [enums.ExclusionCriteria.NO_NEAT_APPEARANCE]: 'keine gepflegtes erscheinungsbild',
    [enums.ExclusionCriteria.MOTIVATION_DOES_NOT_FIT]: 'Motivation passt nicht',
    [enums.ExclusionCriteria.NOT_INFORMED]: 'Nicht informiert',
    [enums.ExclusionCriteria.LACK_OF_EXPERIENCE]: 'mangelnde Erfahrung/Reife',
  },
  positivePropertiesObj: {
    [enums.PositiveProperties.ACTIVE]: 'Aktiv',
    [enums.PositiveProperties.AMBITIOUS]: 'Ambitioniert',
    [enums.PositiveProperties.ATTENTIVE]: 'Aufmerksam',
    [enums.PositiveProperties.DISCIPLINED]: 'Diszipliniert',
    [enums.PositiveProperties.EHRGEIZIG]: 'Ehrgeizig',
    [enums.PositiveProperties.EXPERIENCED]: 'Erfahren',
    [enums.PositiveProperties.EXTROVERT]: 'Extrovertiert',
    [enums.PositiveProperties.INTROVERTED]: 'Introvertiert',
    [enums.PositiveProperties.HELPFUL]: 'Hilfsbereit',
    [enums.PositiveProperties.COMMUNICATIVE]: 'Kommunikativ',
    [enums.PositiveProperties.OPEN]: 'Offen',
    [enums.PositiveProperties.SELF_EMPLOYED]: 'Selbstständig',
    [enums.PositiveProperties.SELF_CONFIDENT]: 'Selbstsicher',
    [enums.PositiveProperties.SOCIAL]: 'Sozial',
    [enums.PositiveProperties.GROOMED]: 'Gepflegt',
    [enums.PositiveProperties.RELIABLE]: 'Zuverlässig',
    [enums.PositiveProperties.PUNCTUAL]: 'Pünktlich',
    [enums.PositiveProperties.LINGUISTIC]: 'Sprachlich begabt',
    [enums.PositiveProperties.FRIENDLY]: 'Sympathisch',
  },
  interviewStatusObj: {
    [enums.InterviewStatus.CANCELED]: 'Canceled',
    [enums.InterviewStatus.NO_SHOW_CONFIRMED]: 'No Show Confirmed',
    [enums.InterviewStatus.NO_SHOW_NOT_CONFIRMED]: 'No Show Not Confirmed',
    [enums.InterviewStatus.DISCUSS]: 'Discuss',
    [enums.InterviewStatus.VERIFICATION]: 'Verification',
    [enums.InterviewStatus.HELPER_DECLINED]: 'Helper Declined',
    [enums.InterviewStatus.TOTALLY_DECLINED]: 'Totally declined',
  },
  clientCheckupCallObj: {
    [enums.ClientCheckupCallStatus.NO_CALL]: 'No Call',
    [enums.ClientCheckupCallStatus.NO_ANSWER]: 'No Answer',
    [enums.ClientCheckupCallStatus.VOICE_MAIL]: 'Voice Mail',
    [enums.ClientCheckupCallStatus.SATISFIED]: 'Satisfied',
    [enums.ClientCheckupCallStatus.NOT_SATISFIED]: 'Not Satisfied',
  },

  invitationCheckupCallObj: {
    [enums.InvitationCheckupCallStatus.UNCLARIFIED]: 'Unclarified',
    [enums.InvitationCheckupCallStatus.CLIENT_CONTACTED]: 'Client contacted',
    [enums.InvitationCheckupCallStatus.HELPER_CONTACTED]: 'Helper contacted',
  },
  notReachingTargetReasonObj: {
    [enums.NotReachingTargetReason.CLIENT_ON_VACATION]: 'Client was on Vacation',
    [enums.NotReachingTargetReason.CLIENT_IN_HOSPITAL]: 'Client was in hospital',
    [enums.NotReachingTargetReason.CLIENT_NO_NEED]: 'Client has No Need',
    [enums.NotReachingTargetReason.HELPER_ON_VACATION]: 'I was on vacation',
    [enums.NotReachingTargetReason.HELPER_IN_HOSPITAL]: 'I was Sick',
    [enums.NotReachingTargetReason.VISIT_PROBLEM]: 'Visit Problem',
    [enums.NotReachingTargetReason.NO_MORE_TASKS]: 'Customer has no further tasks for me',
    [enums.NotReachingTargetReason.HAVE_NO_CAPACITY]: 'I have no capacity for further appointments',
    [enums.NotReachingTargetReason.CLIENT_NOT_WANT_USE_BUDGET]: 'Customer does not want to use the credit',
    [enums.NotReachingTargetReason.OTHER]: 'Other',
  },
  targetNotReachedTypeObj: {
    [enums.TargetNotReachedType.SERVICE_HOURS]: 'Service Plan',
    [enums.TargetNotReachedType.BUDGET]: 'Budget',
  },
  months: {
    [enums.Months.JANUARY]: 'January',
    [enums.Months.FEBRUARY]: 'February',
    [enums.Months.MARCH]: 'March',
    [enums.Months.APRIL]: 'April',
    [enums.Months.MAY]: 'May',
    [enums.Months.JUNE]: 'June',
    [enums.Months.JULY]: 'July',
    [enums.Months.AUGUST]: 'August',
    [enums.Months.SEPTEMBER]: 'September',
    [enums.Months.OCTOBER]: 'October',
    [enums.Months.NOVEMBER]: 'November',
    [enums.Months.DECEMBER]: 'December',
  },

  terminationReasonCategoriesObj: {
    [TerminationReasonCategories.INTERNAL]: 'Internal Reasons',
    [TerminationReasonCategories.EXTERNAL]: 'External Reasons',
    [TerminationReasonCategories.UNCLEAR]: 'Unclear Reasons',
    [TerminationReasonCategories.NEW_CLIENT_NOT_INTERESTED]: 'New Client Not intersed',
  },

  clientTerminationReasonsObj: {
    [TerminationReasonCategories.INTERNAL]: {
      [ClientTerminationReasons[TerminationReasonCategories.INTERNAL].BILLING]: 'Dissatisfied (Billing)',
      [ClientTerminationReasons[TerminationReasonCategories.INTERNAL].MATCHING]: 'Dissatisfied (matching)',
      [ClientTerminationReasons[TerminationReasonCategories.INTERNAL].HELPER_QUALITY]: 'Dissatisfied (Helper Quality)',
      [ClientTerminationReasons[TerminationReasonCategories.INTERNAL].SUPPORT]: 'Dissatisfied (Support)',
    },
    [TerminationReasonCategories.EXTERNAL]: {
      [ClientTerminationReasons[TerminationReasonCategories.EXTERNAL].CLIENT_DIED]: 'Client Died',
      [ClientTerminationReasons[TerminationReasonCategories.EXTERNAL].MOVE_TO_NURSING_HOME]: 'Move to nursing Home',
      [ClientTerminationReasons[TerminationReasonCategories.EXTERNAL].TERMINATED_BY_JUHI]: 'Terminated Buy JUHI',
      [ClientTerminationReasons[TerminationReasonCategories.EXTERNAL].TEMPORARY_ORDER]: 'Temporary Order',
      [ClientTerminationReasons[TerminationReasonCategories.EXTERNAL].MISSING_SERVICE_COVERAGE_LOCATION]:
        'Missing Service Coverage (Location)',
      [ClientTerminationReasons[TerminationReasonCategories.EXTERNAL].MISSING_SERVICE_COVERAGE_TYPE]:
        'Missing Service Coverage (Type)',
    },
    [TerminationReasonCategories.UNCLEAR]: {
      [ClientTerminationReasons[TerminationReasonCategories.UNCLEAR].LONG_INACTIVITY]: 'Long Inactivity',
      [ClientTerminationReasons[TerminationReasonCategories.UNCLEAR].OTHER]: 'Other',
    },
    [TerminationReasonCategories.NEW_CLIENT_NOT_INTERESTED]: {
      [ClientTerminationReasons[TerminationReasonCategories.NEW_CLIENT_NOT_INTERESTED].NO_MORE_INTEREST]:
        'NK. No more Interest',
    },
  },

  clientNotInterestedTerminationReasonObj: {
    [TerminationReasonCategories.NEW_CLIENT_NOT_INTERESTED]: {
      [ClientTerminationReasons[TerminationReasonCategories.NEW_CLIENT_NOT_INTERESTED].NO_MORE_INTEREST]:
        'NK. No more Interest',
    },
  },

  helperTerminationReasonsObj: {
    [TerminationReasonCategories.INTERNAL]: {
      [HelperTerminationReasons[TerminationReasonCategories.INTERNAL].ORDER_SUPPLY]: 'Dissatisfied (Order Supply)',
      [HelperTerminationReasons[TerminationReasonCategories.INTERNAL].TASKS]: 'Dissatisfied (Tasks)',
      [HelperTerminationReasons[TerminationReasonCategories.INTERNAL].CLIENTS]: 'Dissatisfied (Clients)',
      [HelperTerminationReasons[TerminationReasonCategories.INTERNAL].SUPPORT]: 'Dissatisfied (Support)',
      [HelperTerminationReasons[TerminationReasonCategories.INTERNAL].PAYMENT]: 'Dissatisfied (Payment)',
    },
    [TerminationReasonCategories.EXTERNAL]: {
      [HelperTerminationReasons[TerminationReasonCategories.EXTERNAL].TIME_AVAILABILITY_SCHOOL]:
        'Time Availability (School)',
      [HelperTerminationReasons[TerminationReasonCategories.EXTERNAL].TIME_AVAILABILITY_VOCATIONAL_TRAINING]:
        'Time Availability (Vocational Training)',
      [HelperTerminationReasons[TerminationReasonCategories.EXTERNAL].TIME_AVAILABILITY_STUDIES]:
        'Time Availability (Studies)',
      [HelperTerminationReasons[TerminationReasonCategories.EXTERNAL].TIME_AVAILABILITY_FSJ]:
        'Time Availability (FSJ, FÖJ, BFD etc.)',
      [HelperTerminationReasons[TerminationReasonCategories.EXTERNAL].TIME_AVAILABILITY_OTHER]:
        'Time Availability (Other)',
      [HelperTerminationReasons[TerminationReasonCategories.EXTERNAL].PRIVATE_CIRCUMSTANCES]: 'Private Circumstances',
      [HelperTerminationReasons[TerminationReasonCategories.EXTERNAL].TERMINATED_BY_JUHI]: 'Terminated Buy JUHI',
      [HelperTerminationReasons[TerminationReasonCategories.EXTERNAL].MOVE_TO_ANOTHER_CITY]: 'Move to Antoher City',
      [HelperTerminationReasons[TerminationReasonCategories.EXTERNAL].NO_MORE_INTEREST]: 'New H. No more Interest',
    },
    [TerminationReasonCategories.UNCLEAR]: {
      [HelperTerminationReasons[TerminationReasonCategories.UNCLEAR].LONG_INACTIVITY]: 'Long Inactivity',
      [HelperTerminationReasons[TerminationReasonCategories.UNCLEAR].OTHER]: 'Other',
    },
  },
  ClientContractLaterReasonsObj: {
    [enums.ContractLaterReasons.CONTRACT_NOT_RECEIVED]: 'Contract not received',
    [enums.ContractLaterReasons.CONTRACT_FAULTY]: 'Contract faulty',
    [enums.ContractLaterReasons.RESEND_REQUESTED]: 'Resend requested',
    [enums.ContractLaterReasons.OTHER]: 'Other',
  },
  helperLevelObj: {
    [enums.HelperLevel.BRONZE]: 'Bronze',
    [enums.HelperLevel.SILVER]: 'Silber',
    [enums.HelperLevel.GOLD]: 'Gold',
    [enums.HelperLevel.DIAMOND]: 'Diamant',
  },

  servicePlanNameObj: {
    [enums.ServicePlan.BASIC]: 'Started',
    [enums.ServicePlan.BEST_CHOICE]: 'Standard',
    [enums.ServicePlan.PREMIUM]: 'Premium',
  },
  tasksCompletedWithinTimeObj: {
    [enums.TasksCompletedWithinTime.NO]: 'No',
    [enums.TasksCompletedWithinTime.RATHER_NO]: 'Rather No',
    [enums.TasksCompletedWithinTime.NEUTRAL]: 'Neutral',
    [enums.TasksCompletedWithinTime.RATHER_YES]: 'Rather Yes',
    [enums.TasksCompletedWithinTime.YES]: 'Yes',
  },
  serviceTypeAnswersObj: {
    [enums.ServiceTypeAnswers.HOME_HELP]: 'Home Help',
    [enums.ServiceTypeAnswers.SHOPPING]: 'Shopping',
    [enums.ServiceTypeAnswers.CARE_ACCOMPANIMENT]: 'Care / Accompaniment',
    [enums.ServiceTypeAnswers.COMBINATION]: 'Combination (of the top 3)',
    [enums.ServiceTypeAnswers.OTHER]: 'Other',
  },
  adminInvitationCreationReasonObj: {
    [enums.AdminInvitationCreationReason.HELPER_CHANGE_CLIENT_REQUEST]: 'Change of helper (client request)',
    [enums.AdminInvitationCreationReason.HELPER_CHANGE_HELPER_REQUEST]: 'Change of helper (helper request)',
    [enums.AdminInvitationCreationReason.HELPER_CHANGE_BLACKLIST_HELPER]: 'Change of helper (blacklist helper)',
    [enums.AdminInvitationCreationReason.ADD_HELPER_BUDGET_USAGE]: 'Additional helper (credit usage)',
    [enums.AdminInvitationCreationReason.ADD_HELPER_OTHER_TASKS]: 'Additional Helper (Other Tasks)',
    [enums.AdminInvitationCreationReason.INITIAL_ALLOCATION_NK]: 'Initial allocation (NK)',
    [enums.AdminInvitationCreationReason.REPLACEMENT]: 'Replacement Order',
    [enums.AdminInvitationCreationReason.MANUAL_DOUBLE_ORDERS_MATCHING]: 'Manual matching of double orders',
    [enums.AdminInvitationCreationReason.OTHER]: 'Other',
  },

  helperOtherJobTypesObj: {
    [enums.HelperOtherJobTypes.FULL_TIME]: 'Full Time',
    [enums.HelperOtherJobTypes.PART_TIME]: 'Part Time',
    [enums.HelperOtherJobTypes.TRAINING]: 'Training',
    [enums.HelperOtherJobTypes.MINI_JOB]: 'Mini Job',
    [enums.HelperOtherJobTypes.SHORT_TERM]: 'Short Term',
  },
  ContactStatusOptionsObj: {
    [enums.ContactStatusOptions.NO_CONTACT]: 'No Contact',
    [enums.ContactStatusOptions.FIRST_CONTACT]: 'First Contact',
    [enums.ContactStatusOptions.SECOND_CONTACT]: 'Second Contact',
  },
  TransferStatusOptionsObj: {
    [enums.HelperOnboardingTransferStatus.NOT_TRANSFERRED]: 'Not Transferred',
    [enums.HelperOnboardingTransferStatus.TRANSFERRED]: 'Transferred',
  },
  UnclearClientsProcessingStatusObj: {
    [enums.UnclearClientsProcessingStatus.unProcessed]: 'Unprocessed',
    [enums.UnclearClientsProcessingStatus.HelperContactedByMail]: 'Helper contacted (Mail)',
    [enums.UnclearClientsProcessingStatus.HelperContactedByCall]: 'Helper contacted (Call)',
    [enums.UnclearClientsProcessingStatus.ClientContactedByMail]: 'Client contacted (Mail)',
    [enums.UnclearClientsProcessingStatus.ClientContactedByCall]: 'Client contacted (Call)',
    [enums.UnclearClientsProcessingStatus.ClientContactedByPost]: 'Client contacted (Post)',
  },
  todoStatusObj: {
    [enums.TodoStatus.InFuture]: 'In the Future',
    [enums.TodoStatus.Missed]: 'Missed',
    [enums.TodoStatus.ProblemReported]: 'Problem Reported',
  },
  todoReportedProblemObj: {
    [enums.TodosProblemReason.CLIENT_NOT_REACHABLE]: 'Client not reachable',
    [enums.TodosProblemReason.NO_PHONE_NUMBER]: 'No phone number',
    [enums.TodosProblemReason.NO_MUTUAL_APPOINTMENT]: 'No mutual appointment',
    [enums.TodosProblemReason.CLIENT_NOT_INTERESTED]: 'Client not interested',
    [enums.TodosProblemReason.OTHER]: 'Other',
  },
  todoProcessingStatusObj: {
    [enums.TodoProcessingStatus.UNPROCESSED]: 'Unprocessed',
    [enums.TodoProcessingStatus.AID_CONTACTED]: 'Aid contacted',
    [enums.TodoProcessingStatus.CUSTOMER_CONTACTED]: 'Customer contacted',
    [enums.TodoProcessingStatus.REASSIGNMENT]: 'Reassignment',
    [enums.TodoProcessingStatus.ASSIGNMENT_WITHDRAWN]: 'Assignment withdrawn',
  },
  rules: {
    termsOfParticipation: {
      certificate: 'Documents upload completed (certificate of good conduct & school certificate)',
      profileCompleted: 'Profile completed',
    },
    personalQuests: {
      visitAtLeastTwoClients: 'Visit at least two clients',
      fulfilTheVisitPlansOfAllClients: 'Fulfil the visit plans of all clients',
      submitAllBillingOnTime: 'Submit all billing on time',
      submitAllVisitReportsOnTime: 'Submit all visit reports on time',
    },
    milestones: {
      juhiCrown: 'JUHI-Crown',
      juhiBox: 'JUHI-Box',
    },
    levels: {
      bronzeAtLeastTwoClientsBilled: 'Bronze: At least two clients billed',
      sliver50Hrs: 'Silver: 50 hours',
      gold150Hrs: 'Gold: 150 hours',
      diamond300Hrs: 'Diamond: 300 hours',
    },
    achievements: {
      normalPraise: 'Normal praise',
      extraordinarPraise: 'Extraordinary praise',
      reactToOrderWithin24Hrs: 'React to order within 24 hours',
      planVisitWithin24HrsAfterAcceptation: 'Plan visit within 24 hours after acceptation',
      dailyLogInReward: 'Daily log in reward',
      pushNotificationsWhereTurnedOnForTheWholeMonth: 'Push notifications where turned on for the whole month',
      hourlyRewardPreHourOnlyConfirmedVisits: 'Hourly reward (pre hour only confirmed visits)',
      helperRecommended: 'Helper recommended',
      clientRecommended: 'Client recommended',
      complaintOnlyWithReason: 'Complaint (only with reason)',
      bigComplaintOnlyWithReason: 'Big complaint (only with reason)',
      useFullServicePlanBudgetForaClient: 'Use full serviceplan budfet for a client',
    },
    rules: {
      ifThereIsaTieFirstleTheMonthlyBestalueOfTheseHelpersAraComparedSecondlyTheDurationOfEpmloymen:
        'If there is a tie, firstle the monthly best value of these helpers ara compared. Secondly the duration of epmloyment is compared.',
      noNegativeScoresArePossible: 'No negative scores are possible.',
      scoresAreAlwaysSavedEvenIfTheAnnualRankingIsRestarted:
        'Scores are always saved, even if the annual ranking is restarted.',
    },
  },
  vacationDates: vacation =>
    `Start Date: ${moment(vacation.startDate).format('DD.MM.YYYY')}  End Date: ${moment(vacation.endDate).format(
      'DD.MM.YYYY',
    )}`,
  achievementsTitle: 'Achievements',
  termsOfParticipationTitle: 'Terms of Participation',
  personalQuestsTitle: 'Personal Quests',
  milestonesTitle: 'Milestones',
  levelsTitle: 'Levels',
  RulesTitle: 'Rules',

  createNewClient: 'Create new client',
  ///// Personal Data
  personalData: 'Personal Data',
  salutation: 'Salutation',
  firstName: 'First Name',
  lastName: 'Last Name',
  birthday: 'Birthday',
  street: 'Street',
  buildingNumber: 'Building Number',
  zipCode: 'Zip Code',
  city: 'City',
  addressExtra: 'Address Extra',
  mailAddressTooltip:
    'If the required location differs from the shipping address, please enter the shipping address here.',
  district: 'District',
  addMailAddress: 'Add Mail Address',
  removeMailAddress: 'Remove Mail Address',
  //////// Contact Data
  contactData: 'Contact Data',
  phone: 'Phone',
  email: 'Email',
  allowNotificationEmails: 'Allow Notification Emails',
  emergencyNumber: 'Emergency Number',
  emergencyNumberTooltip: 'This field is only visible internally.',
  contactPerson: 'Contact Person',
  contactPersonEmail: 'Contact Person Email',
  blocked: 'Blocked',
  shortTermCare: 'Short Term Care',
  shortTermCareYearsLabel: 'For which year was the short-term care requested?',
  preventiveCareAcceptedLabel: 'For which year was the preventive care requested?',
  govAid: 'Gov Aid',
  salesCallAdmin: 'Sales Call Admin',
  showPersonalInfo: 'Show Personal Info',
  showPersonalInfoTooltip: 'If the customer wishes us to contact them before the allocation, then set the field to no.',
  ////////// Billing Details
  billingDetails: 'Billing Details',
  insuranceNumber: 'Insurance Number',
  ikNumber: 'IK Number',
  ikNumberTooltip: 'Starts at 10',
  nursingInsuranceNumber: 'Nursing Insurance Number',
  admissionDate: 'Admission Date',
  admissionDateTooltip: 'Recording date is the day on which the customer interview was conducted.',
  ebAmountPercentage: 'EB Amount Percentage (%)',
  vpAmountPercentage: 'VP Amount Percentage (%)',
  privateExtraAmountPercentage: 'Private Extra Amount (%)',
  invoiceCopy: 'Invoice Copy',
  wantInvoiceCopy: 'Want Invoice Copy',
  wantInvoiceByPost: 'Want invoice by post',
  wantInvoiceByEmail: 'Want invoice by email',
  wantInsuranceCompanyInvoiceCopy: 'Want insurance company invoice copy',
  privateInsurance: 'Private Insurance',
  sendInvoicesToClient: 'Send invoices to client',
  otherStartDate: 'Other Start Date',
  otherStartDateTooltip: 'Does the customer want a later start, which should also be noted in the contract?',
  rededicationVp: 'Rededication VP',
  percentage: 'Percentage',
  vpExtraAcceptedYears: 'Short Term Care Start Date',
  onlyUseVp: 'Only use VP',
  changePercentage: 'Change Percentage',
  applyVpTooltip:
    "The budgets shown above include respite care. If the customer does not want to use respite maintenance, then set this field to 'No'.",
  privateInsuranceTooltip:
    'If the customer does not have a normal insurance number letter + 9 digits or a normal IK number starting with 10, then he is privately insured. The customers actually know that themselves.',
  insuranceNumberTooltip: 'Letter + 9 digits',
  sendInvoicesToClientTooltip:
    'Would the customer like to submit the bills to the health insurance themselves? For privately insured people, it is automatically set to Yes, since we cannot bill the nursing care fund.',
  wantInvoiceCopyTooltip: 'Would the customer like to receive a copy of the invoice as an overview?',
  wantInsuranceCompanyInvoiceCopyTooltip: 'Would the customer like to receive the insurance Company invoice?',
  ////////// Care Data
  careData: 'Care Data',
  noCareLevel: 'No Care Level',
  pensionPermisson: 'Pension Permisson',
  notes: 'Notes',
  internal: 'Internal',
  //////////// Billing Information
  billingInfo: 'Billing Information',
  careLevel: 'Care Level',
  since: 'Since',
  careLevelTwoOrHigherSince: 'Care Level Two or Higher granted since',
  sinceTooltip:
    'If the customer does not give the exact date, please ask in which season. Then take the last month. For example: Winter: then 03/01/2022. The customer should submit the exact date as soon as possible.',
  preventiveCareAccepted: 'Preventive Care Accepted',
  preventiveCareUsed: 'Preventive Care Used',
  vpAcceptedYears: 'Preventive Care Accepted Start Date',
  amount: 'Amount',
  ebBudget: 'EB Budget',
  currentBudget: 'EB Current Budget',
  lastYearBudget: 'EB Last Year Budget',
  lastYearBudgetTooltip: 'If the customer knows the exact budget, please enter it here.',
  otherServiceProviderNow: 'Other Service Provider Now',
  receivesEbFromSocialOffice: 'Receives EB from the social office',
  oldServiceProvider: 'Old Service Provider',
  from: 'From',
  to: 'To',
  vpCurrentBudget: 'VP Current Budget',
  vpCurrentBudgetTooltip:
    'If nothing is entered here, the software assumes, to be on the safe side, that everything has been used.',
  ownShare: 'Own Share',
  division: 'Division',
  budgetTooltip:
    "The customer's budgets are displayed here. Use this to create a booking plan for customers with maximum hours.",
  ////////////// Service Data
  services: 'Services',
  serviceData: 'Service Data',
  serviceNotes: 'Service Notes',
  vaccination: 'Vaccination',
  restrictions: 'Restrictions',
  restriction: 'Restriction',
  support: 'Support',
  pets: 'Pets',
  recommendedByClients: 'Recommended by clients',
  recommendedByHelpers: 'Recommended by helpers',
  thePets: 'The pets',
  smokingHousehold: 'Smoking Household',
  driversLicense: 'Drivers License',
  allergies: 'Allergies',
  allergy: 'Allergy',
  accommodationSituation: 'Accommodation Situation',
  accommodationSituations: 'Accommodation Situations',

  numberOfHours: 'Number of Hours',
  per: 'Per',
  informOnHelperChange: 'Inform On Helper Change',
  smoker: 'Smoker',
  serviceNotesTooltip: 'This description is visible to the helper in the app.',
  notesTooltip: 'This description is only internal and not visible to the helper.',
  //////// Preferences
  preferences: 'Preferences',
  preferredHelperSituation: 'Preferred Helper Situation',
  preferredTimes: 'Preferred Times',
  preferredTime: 'Preferred Time',
  availableDay: 'Available Day',
  addPreferredTime: 'Add Preferred Time',
  removePreferredTime: 'Remove Preferred Time',
  preferredGender: 'Preferred Gender',
  preferredVaccination: 'Preferred Vaccination',
  helperUserLanguage: 'language of the helper',
  preferredGenderTooltip:
    "Select the customer's desired gender here. Allocation for male customers who want an assistant usually takes longer.",
  preferredHelperLevel: 'Preferred Helper Level',
  //////// Miscellaneous
  miscellaneous: 'Miscellaneous',
  marketingChannel: 'Marketing Channel',
  recommendedBy: 'Recommended By',
  client: 'Client',
  helper: 'Helper',
  other: 'Other',
  create: 'Create',
  edit: 'Edit',
  delete: 'Delete',
  dropdownValues: 'Dropdown Values',
  admins: 'Admins',
  createAdmin: 'Create Admin',
  editAdmin: 'Edit Admin',
  admin: 'Admin',
  insuranceCompanies: 'Insurance Companies',
  createInsuranceCompany: 'Create Insurance Company',
  editInsuranceCompany: 'Edit Insurance Company',
  insuranceCompany: 'Insurance Company',
  createService: 'Create Service',
  service: 'Service',
  editService: 'Edit Service',
  serviceCategories: 'Service Categories',
  createServiceCategory: 'Create Service Category',
  serviceCategory: 'Service Category',
  editServiceCategory: 'Edit Service Category',
  errorWhileLoading: 'Error while loading',
  anErrorHasOccurred: 'An error has occurred',
  search: 'Search',
  deletedSuccessfully: 'Deleted successfully',
  deleteConfirmationQuestion: name => `Do you really want to delete "${name}"?`,
  cancel: 'Cancel',
  success: 'Success',
  nursingAddress: 'Nursing Address',
  insuranceCompanyNumber: 'Insurance Company Number',
  number: 'Number',
  fixErrorsAndSubmitAgain: 'Please fix the errors and submit the form again.',
  createdSuccessfully: 'created successfully',
  editedSuccessfully: 'edited successfully',
  editHeader: name => `Edit ${name}`,
  createHeader: name => `Create New ${name}`,
  createAllergy: 'Create Allergy',
  editAllergy: 'Edit Allergy',
  id: 'ID',
  name: 'Name',
  createRestriction: 'Create Restriction',
  editRestriction: 'Edit Restriction',
  createSupport: 'Create Support',
  editSupport: 'Edit Support',
  time: 'Time',
  editTime: 'Edit Time',
  createTime: 'Create Time',
  createAccommodationSituation: 'Create Accommodation Situation',
  editAccommodationSituation: 'Edit Accommodation Situation',
  pet: 'Pet',
  createPet: 'Create Pet',
  editPet: 'Edit Pet',
  yes: 'Yes',
  no: 'No',
  ///////////////// Helper
  nationalities: 'Nationalities',
  createNewHelper: 'Create New Helper',
  status: 'Status',
  start: 'Start',
  end: 'End',
  contractType: 'Contract Type',
  pensionInsuranceFeePayer: 'Pension Insurance Fee Payer',
  freed: 'freed',
  notFreed: 'not freed',
  taxId: 'Tax-ID',
  pensionInsuranceNumber: 'Pension Insurance Number',
  otherJob: 'Other Job',
  hourlyWage: 'Hourly Wage',
  startDate: 'Start Date',
  endDate: 'End Date',
  invitationStartDate: 'Invitation Start Date',
  jobCenter: 'Job Center',
  housingBenefit: 'Housing Benefit',
  highestSchoolDegree: 'Highest School Degree',
  highestWorkDegree: 'Highest Work Degree',
  interviewDate: 'Interview Date',
  healthInsuranceType: 'Health Insurance Type',
  healthInsurance: 'Health Insurance',
  noContribution: 'No Contribution',
  socialSecurityCalculation: 'Social Security Calculation',
  pensionInsurance: 'Pension Insurance',
  careInsurance: 'Care Insurance',
  payable: 'Payable',
  transitionArea: 'Transition Area',
  uMandatory: 'U-Mandatory',
  hazardPayScale: 'Hazard Pay Scale',
  hazardPayScaleDescription: '5186676:0661 mobile social services',
  taxLevel: 'Tax Level',
  importantInformation: 'Important Information',
  noSmokingHousehold: 'No Smoking Household',
  type: 'Type',
  typeInvitation: 'Type',
  userLanguages: 'User Languages',
  language: 'Language',
  fearOfPets: 'Fear of Pets',
  whichPets: 'Which Pets',
  typeOfService: 'Type of Service',
  workDistricts: 'Work Districts',
  workHoursPerWeekFrom: 'Work Hours Per Week From',
  workHoursPerWeekTo: 'Work Hours Per Week To',
  birthCity: 'Birth City',
  birthCountry: 'Birth Country',
  applyPreferredHelperGender: 'Apply Preferred Helper Gender',
  applyPreferredClientGender: 'Apply Preferred Client Gender',
  matchPetsPreference: 'Match Pets Preference',
  matchSmokingHouseholdPreference: 'Match Smoking Household Preference',
  matchPreferredTime: 'Match Preferred Time',
  hasDriversLicense: 'Has Drivers License',
  applyPreferredVaccination: 'Apply Preferred Vaccination',
  matchLanguage: 'Match Language',
  filters: 'Filters',
  yearsOld: 'Years Old',
  previouslyWorkedWith: (number, type) =>
    number > 0
      ? `Previously worked with ${number} ${type === 'helper' ? (number > 1 ? 'helpers' : 'helper') : ''}`
      : 'No helpers have been assigned to this client yet',
  all: 'All',
  proficiency: 'Proficiency',
  level: 'Level',
  levelOneSince: 'Level 1 Since',
  levelTwoOrHigherSince: 'Level 2 or Higher Since',
  ebAvailableBudget: 'EB Available Budget',
  vpAvailableBudget: 'VP Available Budget',
  validStartDate: 'Valid Start Date',
  validEndDate: 'Valid End Date',
  document: 'Document',
  workLocation: 'Work Location',
  filterHasHelper: {
    [enums.ActiveHelpers.HAS_HELPER]: 'Yes',
    [enums.ActiveHelpers.HAS_NO_HELPER]: 'No',
  },
  filterShowPhoneNumber: {
    [enums.ShowPersonalInfo.SHOW_PHONE_NUMBER]: 'Yes',
    [enums.ShowPersonalInfo.NOT_SHOW_PHONE_NUMBER]: 'No',
  },
  hasHelper: 'Has Helper',
  showPhoneNumber: 'Show Phone Number',
  invoiceType: 'Invoice Type',
  noPreference: 'No Preference',
  notSetYet: 'Not Set Yet',
  few: 'Few',
  // Helper Interview
  educationLevel: 'Education Level',
  futurePlans: 'Future Plans',
  spendFreeTime: 'Spend Free Time',
  isFirstJob: 'Is First Job',
  previousPartTimeJobs: 'Previous Part Time Jobs',
  gainWorkExperience: 'Gain Work Experience',
  activitiesExperiences: 'Activities Experiences (e.g. sports, music, theater, etc.)',
  importantForWork: 'Important For Work',
  personalGoals: 'Personal Goals',
  socialFieldExperience: 'Social Field Experience (e.g. voluntary work, internships, etc.)',
  which: 'Which',
  whyHireYou: 'Why hire you?',
  dealWithDifficultSituation: 'How do you deal with difficult situations? (e.g. conflicts, etc.)',
  howLongWorking: 'How long have you been working in the social field?',
  positiveProperties: 'Positive Properties',
  exclusionCriteria: 'Exclusion Criteria',
  personalAssessment: 'Personal Assessment',
  notSpecified: 'Not Specified',
  helperAssessment: 'Helper Assessment',
  whatAreYouLookingFor: 'What are you looking for?',
  searchAndApplyFilters: 'Search and Apply Filters',
  powerOfAttorney: 'Power Of Attorney',
  mailAddress: 'Mail Address',
  homeTelephone: 'Home Telephone',
  hours: 'Hours',
  required: 'Required',
  notRequired: 'Not Required',
  startDateErrMsg: 'Start Date must be before end Date',
  endDateErrMsg: 'End Date must be after start Date',
  addPlan: 'Add Plan',
  removePlan: 'Remove Plan',
  howToReceiveBill: 'How do you want to receive the bill?',
  validate: 'Validate',
  moveToClients: 'Move to clients',
  suggestPlans: 'Suggest Plans',
  suggestionsAdded: 'Suggestions was added based on your budget',
  rejectInvitationTooltip: `This helper has rejected the invitation from the client. You can fix the refusal to send him the invitation again`,
  helperRejectClientPending: `This helper has <span style="color:${dangerColor[0]};">rejectedt</span> the invitation. The invitation is <span style="color:#4285F4;">still open</span> for other helpers.
  For more info go to the `,
  clientSide: `client Side`,
  helperRejectClientAccept: `This helper has <span style="color:${dangerColor[0]};">declined</span> the invitation.
  But another helper accepted the job`,
  helperRejectClientReject: `All helpers have <span style="color:${dangerColor[0]};">rejected</span> the invitation from this client`,
  helperExpiredClientAccepted: `Another Helper <span style="color:${primaryColor[0]};">accepted</span> the invitation before this helper.`,
  helperAcceptedDeleted: `This invitation <span style="font-weight: bold; text-decoration: underline;">was</span> <span style="color:${primaryColor[0]};">accepted</span>. But it was withdrawn from the helper`,
  helperRejectedDeleted: `This invitation was <span style="color:${dangerColor[0]};">rejected</span> by helpers. However, this refusal was deleted in order to send him the invitation again`,
  applyCanAcceptClientsMarkers: 'Apply can accept clients',
  removeHelpersThatCantAcceptClients: 'Remove helpers without capacities',
  removeTerminatedHelpers: 'Remove terminated helpers',
  removeDeclinedHelpers: 'Remove declined helpers',
  removeClients: 'Remove clients',
  idleHelpers: 'Idle helpers',
  noAcceptedInvitation: 'No accepted invitation',
  hasAcceptedInvitation: 'Has accepted invitation',
  invitationIsRejected: 'Invitation is rejected',
  invitationIsAccepted: 'Invitation is accepted',
  invitationIsPending: 'Invitation is pending',
  notInvited: 'Not invited',
  markers: 'Markers',
  newHelper: 'New Helper',
  download: 'Download',
  fileType: 'File type',
  colored: 'Colored',
  front: 'Front',
  and: 'and',
  back: 'Back',
  title: 'Title',
  description: 'Description',
  bugAttachments: 'Bug Attachments',
  priority: 'Priority',
  formatNotSupported: 'File format not supported for preview',
  expectationsJUHI: 'Expectations JUHI',
  noDocuments: 'NO DOCUMENTS',
  noSignatures: 'NO SIGNATURES',
  declineModalMessage:
    'Are You Sure you want to decline only these Visits, If you confirmed you will not be able to decline any visits for this client',
  declineModalTitle: 'Confirm Declining Visits',
  proceed: 'Proceed',
  declineNote: 'Decline Note',
  decline: 'Decline',
  errMessage: 'An error has occurred',
  declineSuccessfully: 'Visit Declined successfully',
  noClients: 'No Client',
  previousClient: 'Previous Client',
  nextClient: 'Next Client',
  downloadCount: 'Download Count',
  createdAt: 'Created At',
  deletedAt: 'Deleted At',
  completedAt: 'Completed At',
  invoiceNo: 'Invoice No.',
  includeImages: 'Include Images',
  visits: 'Visits',
  noVisitsDeclined: 'You did NOT decline any visits',
  unlock: 'Unlock',
  lock: 'Lock',
  formErr: 'Please fix the errors and submit again.',
  helpers: 'Helpers',
  clientName: 'Client Name',
  clientStatus: 'Client Status',
  endTime: 'End Time',
  startTime: 'Start Time',
  bonus: 'Bonus',
  moreDetails: 'More Details',
  billed: 'Billed',
  confirmPayment: 'Confirm Payment',
  details: 'Details',
  totalAmount: 'Total Amount',
  hoursMonth: 'Hours/Month',
  helperName: 'Helper Name',
  notYet: 'Not Yet',
  paid: 'Paid',
  bill: 'Bill',
  notConfirmed: 'Not Confirmed',
  confirmed: 'Confirmed',
  year: 'Year',
  month: 'Month',
  otherDocuments: 'Other Documents',
  allDocuments: 'All Documents',
  acceptedAt: 'Accepted At',
  targetNotReachedReason: 'Target was Not Reached Because',
  info: 'Info',
  endDateSet: 'End date is set',
  endDateSetSuccessfully: 'Invitation scheduled to be deleted at the end of the month',
  close: 'Close',
  startInvitationLoop: 'Start Invitation Loop',

  disableScheduledInvitationTooltip:
    "You can't start a new standard order, as there is already a standard order scheduled for the future.",
  disablePendingInvitationTooltip:
    "You can't start a new standard order, as there is already a standard order in the order status pending or hold.",
  confirmStartInvitation:
    'You are in the process of matching a second helper. There are current orders in the status accepted or matched. Are you sure you want to match a second helper?',
  invitationTooltip: 'Create a new invitation',
  finish: 'Finish',
  next: 'Next',
  later: 'Later',
  now: 'Now',
  startDateStepQuestion: 'When should the order start?',
  moreInfoNeeded: 'Do you need more information to get started?',
  replacementOrderLabel: 'replacementOrderLabel',
  standerOrderLabel: 'standerOrderLabel',
  replacementHelper: 'Replaced Helper',
  invitationType: 'Invitation Type',
  invitationTypeLabel: 'Invitation Type',
  noExtraNoteNeeded: 'No Extra information',
  needExtraNote: 'Extra informatiom is needed',
  extraInfo: 'Extra Infos',
  endDateStepQuestion: 'When should the order end?',
  manualOrAutomatic: 'What type of allocation is desired?',
  unlimited: 'Unlimited',
  limited: 'Limited',
  inputHelperName: 'Input Helper Name',
  automatic: 'Automatic',
  list: 'List',
  replacement: 'Replacement',
  standard: 'Standard',
  automaticOrManual: 'Automatic Or Manual',
  summary: 'Summary',
  extraNotes: 'Extra Notes',
  loopsCount: 'Loops Count',
  sendedType: 'Send Type',
  stage: 'Stage',
  actions: 'Actions',
  manual: 'Manual',
  dayCreatedAt: 'Day Created At',
  journeyTimeInMinutes: 'Journey Time In Minutes',
  stageCreatedAt: 'Stage Created At',
  helperId: 'Helper ID',
  clientTermination: 'Client Termination',
  orderFailed: 'Order Failed',
  startNewLoop: 'Start New Loop',
  invitationsOnHoldTitle: 'Loop Without Match',
  failedToast: 'Invitation changed to failed successfully',
  failedModal: 'Are you sure you want to change invitation to failed?',
  newLoopToast: 'Started a new loop for the invitation successfully',
  newLoopModal: 'Are you sure you want to start a new loop?',
  invitationsOnHoldDescription: 'Client invitations on status hold after finishing the loop',
  fillPlans: 'Fill Plans',
  clientPlans: 'Client Plans',
  plans: 'Plans',
  invitation: 'Invitation',
  signatureDeletedSuccessfully: 'Signature deleted successfully',
  monthlyConfirmation: 'Monthly Confirmation',
  monthlySignature: 'Monthly Signature',
  signatures: 'Signatures',
  deleteSignature: 'Delete Signature',
  deleteSignatureModalContent: 'Are you sure you want to delete this signature? this will make this visit unconfiremd',
  deleteSignatureModalHeader: 'Delete Visit Signature!!',
  deleteMonthlySignatureModalHeader: 'Delete Monthly Signature!!',
  deleteMonthlySignatureModalContent:
    'Are you sure you want to delete this signature? by deleting this signature all the visits signatures for this client will be deleted',
  visitsSignatures: 'Visits Signatures',
  acceptedInvitations: 'Accepted Invitations',
  acceptedInvitationsDescription: 'Accepted client invitations that are not matched yet',
  pendingInvitations: 'Pending Invitations',
  pendingInvitationsDescription: 'Pending/Scheduled client invitations',
  searchByAddress: 'search By Address',
  searchByPhone: 'Search By Phone',
  thisInvitationHasCompletedHelperInvitation:
    'This invitation has a completed helper invitation but its invoices has not been calculated yet',
  firstPhone: 'First Phone',
  otherPhone: 'Other Phone',
  emergencyContactName: 'Emergency Contact Name',
  emergencyContactPhone: 'Emergency Contact Phone',
  emergencyContactHomeTelephone: 'emergency Contact Home Telephone',
  emergencyContactEmail: 'Emergency Contact Email',
  blockedHelpers: 'Blocked Helpers',
  editBlocked: 'Edit Blocked',
  completionDate: 'Completion Date',
  youCanChooseLastMonth: 'You can choose last month for completion date',
  editInvitationTitle: 'Edit Invitation',
  startNow: 'Start Now',
  averageAcceptTime: 'Average Accept Time',
  averageTravelTimeTooltip:
    'Sum of travel times of all accepted orders in this period of time/number of all accepted orders in this period of time',
  averageTravelTimeDescription: 'the averge of transportaion time for any helper',
  averageTravelTimeTitle: 'Average Travel Time',
  inProgressOverActivePercentageTooltip:
    'Number of all orders in matching Process (HOLD + PEDNDING) right now / Number of all orders(HOLD + PEDNDING + ACCEPTED+ MATCHED)',
  inProgressOverActivePercentageDescription: '(HOLD + PEDNDING)/(HOLD + PEDNDING + ACCEPTED + MATCHED)',
  inProgressOverActivePercentageTitle: 'Fluctuation Precentage',
  terminationReason: 'Termination Reason',
  helperTerminationsTitle: 'Helper Terminations',
  accept: 'Accept',
  declineReason: 'Decline Reason',
  declineOtherReason: 'Decline Other Reason',
  terminationDeclineReason: 'Please enter why did you decline this helper request',

  solved: 'Solved',
  uploadHelperTerminationMessage:
    'Terminating the helper will trigger scheduled automatic invitations for all his clients.',
  uploadHelperTerminationTooltip:
    'This function is intended exclusively for regular and timely terminations according to §622 BGB. If the helper wants to leave JUHI sooner, the termination agreement must be used within the JUHI app.',
  invitationStatus: 'Invitation Status',
  pending: 'Pending',
  scheduled: 'Scheduled',
  sortByCreatedAt: 'Sort By Created At',
  ascending: 'Ascending',
  descending: 'Descending',
  automaticInvitationStartDate: 'State Date',
  automaticInvitationEndDate: 'End Date',
  automaticInvitationExtraInfos: 'Extra Infos',
  automaticInvitationMatching: 'Matching',
  automaticInvitationServiceHours: 'Service Hours',
  automaticInvitationSummary: 'Summary',
  automaticInvitationTypeStandard: 'Standard Order',
  automaticInvitationTypeReplacement: 'Replacement Order',
  automaticInvitationTypeToolTip: 'Automatic invitations are invitations that are created by the system',
  automaticInvitationTypeStandardToolTip:
    'This is a standard order, you are about to permanently assign a new helper to this customer.',
  automaticInvitationTypeReplacementToolTip:
    'This is a replacement order, you are about to temporarily replace a disabled helper while they are unavailable.',
  automaticInvitationStartDateTooltip: `Choose Now and the matching process will begin with the assignment of a suitable helper right after the order is placed.
    If you choose a later time, the matching process will start automatically at least 10 days before the order starts.
    The helper's appointments can only be entered in the app after the selected time.`,
  automaticInvitationEndDateTooltip: `If you only want temporary help from JUHI, select Temporary.
  If you want help to be assigned to this customer on a permanent basis, select Perpetual.
  If an order end date is selected, no more appointments can be entered after this.`,
  automaticIncitationExtraInfosTooltip: `Is order-specific additional information relevant that is only temporarily valid?
     E.g. Reason for the inability of the assigned helper, assignment as a second helper, currently a lot of budget available, etc.
     Helpers see this additional information in the app in addition to the order text.`,
  automaticInvitationNoExtraInfo: `No extra information necessary.`,
  automaticInvitationExtraInfo: `Additional information required.`,
  autoOrManualTooltip: `Choose between Automatic and Manual.
   With automatic allocation, all suitable helpers who live nearby receive the order.
   With manual assignment, a specific helper is selected and assigned directly. Of course, this must be agreed in advance with all parties involved.`,
  inputHelperNameLabel: 'New Helper',
  helperViewer: 'Helper Viewer',
  age: 'Age',
  gender: 'Gender',
  jobDescription: 'Job Description',
  address: 'Address',
  workHours: 'Work Hours',
  helperServices: 'Services',
  dateOfIncident: 'Date Of Incident',
  newOrders: 'New Orders',
  newOrdersStatus: 'New Orders Status',
  orderAcceptanceStage: 'Order Acceptance Stage',
  days: 'Days',
  recent: 'Recent',
  pervious: 'Pervious',
  clientsWithoutHelpers: 'Clients Without Helpers',
  newClientWithHelpers: 'New Client With Helpers',
  clientsWithHelpers: 'Clients With Helpers',
  orderTypes: 'Order Types',
  confirmAllTitle: 'Confirm All Months Confirmation',
  confirmAllButton: 'Confirm All Months Confirmation',
  confirmAllMessage: 'Are you sure you want to crate/confirm all monthly confirmation',
  confirmAllSuccess: 'Confirm All Months Confirmation Successfully',
  completeInvitationAndTerminate: 'Complete Invitation And Terminate',
  completeInvitationAndTerminateSuccess: 'Invitations Completed Successfully and Clients/Helpers are Terminated',
  completeInvitationAndTerminateMessage:
    'Are you sure you want to terminate all helpers/clients with scheduled termination date and complete all invitations with end date during and before this month',
  completeInvitationAndTerminateTooltip:
    'This button terminates all helpers/clients with scheduled termination date and completes all invitations with end date during and before this month',
  cantCompleteInvitationAndTerminateTooltip:
    'You can only complete invitations and terminate clients/helpers in the previous months',
  completeInvitationAndTerminateTitle: 'Complete Invitation And Terminate',
  createOrConfirmMonthlyVisit: 'Create/Confirm Monthly Confirmation',
  createOrConfirmMonthlyVisitQuestion:
    'Are you sure you want to create or confirm (if exists) this monthly confirmation?',
  monthConfirmedSuccessfully: 'Month confirmed successfully',
  blockedHelpersUpdatedSuccessfully: 'Blocked Helpers Updated Successfully',
  viewHelperInvitations: 'View Helper Invitations',
  startInvitationNow: 'Start Invitation Now',
  cancelInvitation: 'Cancel Invitation',
  firstVisit: 'First Visit',
  planned: 'Planned',
  notPlanned: 'Not Planned',
  noSuggestions: 'No Suggestions to be added',
  pendingInvitation: 'Pending Invitation',
  rejectedInvitation: 'Rejected Invitation',
  acceptedInvitation: 'Accepted Invitation',
  applyStageCreatedAt: 'Apply Stage Created At',
  removeNotInvited: 'Remove Not Invited',
  vacationDeclineReason: 'Vacation Decline Reason',
  acceptVacationRequestModalTitle: 'Accept Vacation Request',
  acceptVacationRequestModal: 'Are you sure you want to Accept this vacation request',
  acceptVacationRequestToast: 'Helper Vacation Request is Accepted Successfully',
  VacationRequestsTitle: 'Vacation Requests',
  VacationRequestsDescription: 'Helpers who want to take a vacation during a certain period',
  confirmedAt: 'Confirmed At',
  sortByAcceptedAt: 'Sort By Accepted At',
  averageMatchSpeedTooltip:
    'The difference in days from visit confirmed at - helper invitation accepted at invitation turned from Accepted to Match',
  averageMatchSpeedDescription: 'The time the helper needed to have his first confirmed visit with a new client',
  averageMatchSpeedTitle: 'Averge Match Speed Time',
  duration: 'Duration',
  matchSecondHelper: 'Match second Helper',
  applyInvitationStatus: 'apply Invitation Status',
  mapIconTooltip: 'Show Helpers Near client',
  pendingDaysCount: 'pending Days Count',
  targetStage: 'target Stage',
  loopCreatedAt: 'loop Created At',
  goToStage4: 'Go To Stage 4',
  goToStage3: 'Go To Stage 3',
  goToStage2: 'Go To Stage 2',
  removeHelpersWithNoVisits: 'Remove Helpers With No Visits',
  newHelpers: 'New Helpers',
  relationshipWithClient: 'Relationship With Client',
  phoneNumber: 'Phone Number',
  availabilityNote: 'at what time is the client best reachable?',
  availabilityNoteHelper: 'at what time is the helper best reachable?',
  showInApp: 'Show In App',
  hasVoiceMail: 'Has Voice Mail',
  isMainNumber: 'Main Number',
  isClientNumber: 'Client Own Number',
  addPhoneNumber: 'Add Phone Number',
  removePhoneNumber: 'Remove Phone Number',
  showAllDone: 'Show All Done',
  hideAllDone: 'Hide All Done',
  terminateWithoutDocument: 'Terminate Without Document',
  unBill: 'Unbill',
  approveDiscount: 'Approve Discount',
  remaining: 'Remaining',
  approveDiscountTooltip: 'The selected client will receive 2 free hours on their upcoming invoices.',
  miscellaneousDiscountUsedTooltip:
    'The selected client has already used their 100 euros discount therefore marketing channel cannot be changed.',
  adminApproved: 'Admin Approved',
  miscellaneousRewardTakenTooltip:
    'The selected helper has already received their reward or the reward was created in a previous month therefore marketing channel cannot be changed.',
  approveReward: 'Approve Reward',
  approveRewardTooltip: 'The selected helper will receive their reward on their upcoming invoice.',
  creationReason: 'Creation Reason',
  currentStage: 'Current Stage',
  helperTermination: 'Helper Termination',
  helperVacation: 'Helper Vacation',
  contractDuration: 'Contract Duration',
  terminationDate: 'Termination Date',
  terminationCanceledSuccessfully: 'Termination Canceled Successfully',
  cancelTerminationText: 'Are you sure you want to cancel client termiantation?',
  cancelTermination: 'Cancel Termination',
  byHelper: 'By Helper',
  reason: 'Reason',
  draft: 'Draft',
  removeTerminatedClients: 'remove Terminated Clients',
  clientWithPendingInvitationsOnly: 'Client With Pending Invitations Only',

  averageHoursPerHelper: 'Hours Per Helper',
  averageHoursPerHelperDescription: 'Confirmed Visits Hours/All Helpers Counts Per Month',
  averageHoursPerActiveHelper: 'Hours Per Active Helper',
  averageHoursPerActiveHelperDescription: 'Confirmed Visits Hours/Active Helpers Counts Per Month',
  averageHoursPerClient: 'Hours Per Client',
  averageHoursPerClientDescription: 'Confirmed Visits Hours/All Clients Counts Per Month',
  averageHoursPerActiveClient: 'Hours Per Active Client',
  averageHoursPerActiveClientDescription: 'Confirmed Visits Hours/Active Clients Counts Per Month',
  isDraft: 'Is Draft',
  rewarded: 'Rewarded',
  deadline: 'Deadline',
  PerformanceMonth: 'Performance Month',
  sendDate: 'Send Date',
  reminderNumber: 'Reminder Number',
  lastReminderDate: 'Last Reminder Date',
  reminder: 'Reminder Client',
  reminderCount: 'Reminder Count',
  addInvoiceAddress: 'Add Invoice Address',
  removeInvoiceAddress: 'Remove Invoice Address',
  invoiceAddressStreet: 'Invoice Address Street',
  invoiceAddressBuildingNumber: 'Invoice Address Building Number',
  invoiceAddressZipCode: 'Invoice Address Zip Code',
  invoiceAddressCity: 'Invoice Address City',
  invoiceAddressFirstName: 'Invoice Address First Name',
  invoiceAddressLastName: 'Invoice Address Last Name',
  budgetPlan: 'Budget Plan',
  mitVp: 'Mit VP',
  hoursPerMonth: 'Hours Per Month',
  temporaryContractDeclineReason: 'Enter the reason of declining the uploaded contract ',
  temporaryContract: 'Temporary Contracts Table',
  temporaryContractDescription: 'clients with temporary Contracts',
  temporaryDocumentApproved: 'You have Approved This Contract Successfully',
  temporaryDocumentDeclined: 'You have Decliend This Contract Successfully',
  contractStartDate: 'Contract Start Date',
  deleteVisitModal: 'are you sure You want to delete this Visit?',
  createVisit: 'Create Visit',
  editVisit: 'Edit Visit',
  invoiceReminder: 'Invoice Reminder',
  letterTypes: 'Letter Types',
  jiraStatus: 'Jira Status',
  adminName: 'Admin Name',
  uploadCsvNote: 'Note: Make sure you have these columns in your csv (PLZ , Ort)',
  hasPlannedVisits: 'Has planned Visits Only',
  visitReport: 'Visit Report',
  consent: 'Consent',
  viaEmail: 'Send Via E-Mail',
  viaSMS: 'Send Via SMS',
  helperVacationWithinTerminationPeriodTooltip: 'Vacation is within helper termination period',
  customerNotActiveThisMonth: 'The customer was not active this month....',
  lastConfirmedVisitDate: 'Last Confirmed Visit Date',
  confirmTerminatingInPast: 'Terminating in the past will delete the following visits/monthly confirmations',
  h: 'H',
  privateBilling: 'Private Billing',
  servicePlans: 'Service Plans',
  servicePlanDowngradeTitle: 'Service Plan Downgrade',
  servicePlanNow: 'Service Plan Now',
  downgradeTo: 'Downgrade To',
  termsOfServicePlan: 'Terms Of Service Plan',
  bookedHours: 'Booked Hours',
  deviationBy: 'Deviation By',
  downgradePlan: 'Downgrade Plan',
  ignore: 'Ignore',
  editClient: 'Edit Client',
  onlyActive: 'Only Active',
  invoiceCopyCount: 'Invoice Copy Count',
  InvoiceEmail: 'Invoice E-Mail',
  InactiveHelpersTitle: 'InActive Helpers',
  InactiveHelpersDescription: 'Helpers who are not active in the last 15 days',
  editServiceHours: 'Edit Service Hours',
  servicePlan: 'Service Plan',
  downgradePlanTooltip: "Can't downgrade because client is already on the basic plan",
  unableToChangeReward: "You can't change helper reward created in a previous month",
  acceptHelperDocumentConfirmationQuestion: 'Are you sure you want to accept this document?',
  declineHelperDocumentConfirmationQuestion: 'Are you sure you want to decline this document?',
  acceptedSuccessfully: 'Accepted Successfully',
  declinedSuccessfully: 'Declined Successfully',
  uploadedDocumentDeclineReason: 'Enter the reason of declining the helper uploaded document ',
  cannotPreviewFileNotFound: 'Cannot preview, file not found',
  excludeBerlin: 'Exclude Berlin',
  developers: 'Developers',
  ranking: 'Ranking',
  bugs: 'Bugs',
  budgetNotReached: 'Budget Not Reached',
  serviceHoursNotReached: 'Service Hours Not Reached',
  targetNotReachedReasons: 'Target Not Reached Reasons',
  hoursWithoutCapacity: 'hours not used',
  unusedCapacityServicePlansTitle: 'Only Service Plans',
  unusedCapacityServicePlansTooltip:
    'Sum of all helper capacities – Sum of Max(service plans,budgets) who are within automatic invitations',
  unusedCapacityServicePlansDescription: 'Helpers capacity does not meet service plans',
  unusedCapacityBudgetTooltip:
    'Sum of all helper capacities – Sum of Max(service plans,budgets) who are within automatic invitations',
  unusedCapacityBudgetDescription: 'Helpers capacity does not meet service plans+ budgets',
  unusedCapacityBudgetTitle: 'Service Plan with Budget',
  prioritized: 'Prioritized',
  showVisitReport: 'Show Visit Report',
  additionalActivities: 'Additional Activities',
  generalInformation: 'General Information',
  twoMonthInRow: 'Two Month In Row',
  automaticInvitation: 'Automatic Invitation',
  paused: 'Paused',
  attributes: 'Attributes',
  firstPlannedVisit: 'First Planned Visit',
  processingStatus: 'Processing Status',
  unclearClientsDescription: 'Unclear Clients in Previous Month',
  unclearClientsTitle: 'Unclear Clients',
  capacity: 'Helper Capacity',
  noCapacity: 'Has No Capacity',
  totalHours: 'Total Hours',
  serviceTypeQuestion: 'What was the focus of the service today?',
  tasksCompletedWithinTimeQuestion: 'Were the customer’s tasks/requests completed within the allotted time?',
  additionalActivitiesQuestion: 'Were other activities carried out in addition to the usual ones? If yes, which?',
  generalInformationQuestion: "How did today's appointment go? What have you done?",
  withBudget: 'With Budget',
  hrs: 'Hrs',
  emptyContractWarning: 'Warning: Missing fields in client form will be missing in the contract',
  clientPreferences: 'Client Preferences',
  otherReasons: 'Other Reasons',
  budgetHours: 'Budget Hours',
  visitsHours: 'Visits Hours',
  notReachingTargetReasons: 'Not Reaching Target Reasons',
  remainingBudget: 'Remaining Budget',
  exceedingHours: 'Exceeding Hours',
  exceedingHoursTitle: 'Exceeding Hours',
  exceedingHoursDescription: 'Clients who will exceed their budget if used same hours as last month',
  exceedingHoursSolvedToast: 'Client exceeding hours changed solved status successfully',
  visitHoursLastMonth: 'Visit Hours Last Month',
  adminReasonQuestion: 'What is the reason for creating the invitation?',
  adminReasonLabel: 'Admin Reason',
  adminNotes: 'Admin Notes',
  contract110: 'Contract 110',
  contract110HelperTask: 'Helpers with Contract of type 110 and working days more than 60',
  relationshipWithHelper: 'Relationship With Helper',
  isHelperNumber: 'Helper Own Number',
  useForServiceRequests: 'Use For Service Requests',
  remainingUnsolved: 'Remaining Unsolved',
  nursingAdvisor: 'Nursing Advisor',
  nursingAdvisors: 'Nursing Advisors',
  adminReasonExplain: 'Extra Explaination (Optional)',
  otherReason: 'Other Reason',
  extraExplain: 'Extra Explainations',
  useServiceHoursInsteadOfBudget: 'Use Service Hours Instead Of Budget',
  clientRelativeName: 'Relative Client Name',
  clientRelative: 'Relative Client',
  hourlyOverrunSolved: 'Hourly overrun solved successfully',
  hourlyOverrunTitle: 'Hourly Overrun',
  hourlyOverrunDescription: 'Helpers who worked more than 35 hours this month',
  confirmedHours: 'Confirmed Hours',
  contractInformation: 'Contract Information',
  otherJobType: 'Other Job Type',
  recruiterAdmin: 'Recruiter Admin',
  onboardingContracts: 'Onboarding Contracts',
  onboardingCertificates: 'Onboarding Certificates',
  hourlyOverrun: 'Hourly Overrun',
  uploadedCertificates: 'Uploaded Certificates',
  pendingCertificates: 'Pending Certificates',
  declinedCertificates: 'Declined Certificates',
  uploadedContracts: 'Uploaded Contracts',
  pendingContracts: 'Pending Contracts',
  declinedContracts: 'Declined Contracts',
  unsavedChanges: 'You have unsaved changes. Are you sure you want to leave without saving?',
  parentPhoneNumber: 'Parent Phone',
  parentEmail: 'Parent E-mail',
  underAgeNote:
    'The helper is a minor. For this reason, the employment contract must also be signed by a legal guardian. Please check the signature and contact the legal guardian directly if in doubt.',
  firstLetterUppercase: 'first letter must be capital',
  lettersOnly: 'only letters are allowed',
  familyStatus: 'Family Status',
  accountHolder: 'Account Holder',
  bankInstitute: 'Bank Institute',
  uploadedWorkInstructions: 'Uploaded Work Instructions',
  accepted: 'accepted',
  declined: 'declined',
  numberOfDaysSinceAccepted: 'Number Of Days Since Accepted',
  recruiterAdminName: 'Recruiter Admin Name',
  finishedTasks: 'Finished Tasks',
  contactStatus: 'Contact Status',
  processFailed: 'Process Failed',
  helperOnboardingsDescription: 'Helpers who have not completed the onboarding process',
  helperOnboardingsTitle: 'Helfer-Onboarding',
  notStarted: 'Not Started',
  completed: 'Completed',
  disabled: 'Disabled',
  incomplete: 'Incomplete',
  allocationInfo: 'Allocation Info',
  personalInfo: 'Personal Info',
  training: 'Training',
  idVerification: 'ID Verification',
  onboardingVideo: 'Onboarding Video',
  workInstructions: 'Work Instructions',
  helperContract: 'Vertragsdokumente',
  onboardingTasks: 'Onboarding Tasks',
  trainingCertificates: 'Training Certificates',
  startDateMustBeforeEndDate: 'Start date must be before the end date',
  mustBe11Characters: 'Must be 11 characters',
  bicLengthValidation: 'Must be 8 or 11 characters',
  bicValidation: 'Only capital letters and numbers are allowed',
  iBanValidation: "Must start with 'DE' followed by 20 numbers",
  emailNotValid: 'Email not valid',
  decision: 'Decision',
  contracts: 'Contracts',
  onboardings: 'Onboardings',
  onboardingProcessFailedQuestion:
    'Are you sure you want to cancel the onboarding process for this helper? The helper will lose access to the app and will therefore no longer have a chance to successfully complete the onboarding process.\n\nCancel / Confirm\n\nConfirm is red and means that the admin is sure to set this helper to failed.',
  onboardingProcessFailed: 'Cancel onboarding process?',
  onboardingProcessFailedSuccess: 'Onboarding process canceled',
  numberOfDaysToComplete: 'Days to Complete',
  inProgress: 'In progress',
  startedAt: 'Started At',
  failedAt: 'Failed At',
  failureReason: 'Failure Reason',
  helperDataVerificationTitle: 'ID Verification',
  acceptHelperDataVerificationQuestion: 'Are you sure you want to accept this information?',
  fail: 'fail',
  idVerificationDeclineReason: 'Enter a reason to decline the helper data',
  automatedDataValidation: 'Stripe Data',
  helperInput: 'Helper Input',
  notificationMessageByAdmin: 'Notification Message to Helper',
  sortByLastConfirmedVisit: 'Sort By Last Confirmed Visit',
  doubleOrders: 'Double Orders',
  distinctHelpers: 'Distinct Helpers',
  relativeClient: 'Relative Client',
  doubleOrdersAcceptedTableDescription:
    "This table shows all cases where either a client or their relative has an automatically created 'Accepted' or 'Matched' invitation due to helper termination or vacation, while the other does not have an invitation.",
  doubleOrdersPendingTableDescription:
    "This table shows all cases where either a client or their relative has a 'Pending' or 'Hold' invitation due to helper termination or vacation, while the other does not have an invitation.",
  acceptedMatchedInvitations: 'Accepted & Matched Invitations',
  reportHandedAt: 'Report Submission Date',
  removeScheduledInvitations: 'Remove Scheduled Invitations',
  updatedSuccessfully: 'Updated successfully',
  editNote: 'Edit note',
  addNote: 'Add note',
  note: 'Note',
  confirm: 'Confirm',
  processed: 'Processed',
  processedEmailStatusDescription:
    'This event is triggered when SendGrid receives an individual message and prepares it for delivery.',
  dropped: 'Dropped',
  droppedEmailStatusDescription:
    'There are various reasons why your email may not even be sent to a recipient. This event informs your system when an email is dropped, along with the reason (e.g., spam content or the recipient unsubscribed).',
  deferred: 'Deferred',
  deferredEmailStatusDescription:
    "When an email cannot be delivered immediately but has not been completely rejected, the 'Deferred' event is triggered. SendGrid will attempt to deliver the deferred message for 72 hours before it is blocked.",
  bounce: 'Bounce',
  bounceEmailStatusDescription:
    "When a server is unable or unwilling to deliver a message, a 'Bounce' event is triggered. This is often due to outdated or incorrect email addresses.",
  delivered: 'Delivered',
  deliveredEmailStatusDescription:
    "When an email is accepted by the receiving server, the 'Delivered' event is triggered. This does not guarantee it will reach the inbox, but it marks the start of further processing.",
  open: 'Open',
  openEmailStatusDescription:
    "An 'Open' event is triggered each time the email is viewed with images enabled. SendGrid uses a transparent image beacon to track opened messages.",
  click: 'Click',
  clickEmailStatusDescription:
    "A 'Click' event is triggered when the recipient clicks a link in your email, signaling engagement with your content.",
  spamReport: 'Spam Report',
  spamReportEmailStatusDescription:
    "If a recipient marks your email as spam, a 'Spam Report' event is triggered. This allows you to address spam complaints or avoid sending to this address in the future.",
  unsubscribe: 'Unsubscribe',
  unsubscribeEmailStatusDescription:
    "An 'Unsubscribe' event is triggered when a recipient opts out of your emails. Removing them from your list promptly can improve your engagement rate and reduce spam reports.",
  newClients: 'New Clients',
  terminatedHelpers: 'Terminated Helpers',
  terminatedClients: 'Terminated Clients',
  quotePercentages: 'Quote Percentages',
  helpersRecommendByCount: 'Helpers Recommended By Count',
  clientsRecommendByCount: 'Clients Recommended By Count',
  clientsCount: 'Clients Count',
  helpersCount: 'Helpers Count',
  clientsCareLevelCount: 'Clients Care Level Count',
  clientsStatistics: 'Clients Statistics',
  helpersStatistics: 'Helper Statistics',
  transferStatus: 'Transfer Status',
  sortByLastUpdatedAt: 'Sort By Last Updated At',
  boostAchieved: 'Boost Achieved',
  moveBackToInProgress: 'move Back To In Progress',
  moveBackToInProgressSuccess: 'Helper successfully moved back to in progress',
  invitationId: 'Invitation Id',
  sendAcceptanceLetter: 'Send Acceptance Letter',
};
